import styled from "styled-components/macro";
import { device } from "../components/Helpers";

export const WhyTreatMacStyles = styled.main`
	padding-top: 9rem;
	
	section {
		margin-top:0;
		&[data-index="1"]{
			margin-bottom:0;
		}
	}
  
	p {
		color: #000000;
  	}

	ul {
		li {
			color: #000000;
			margin-bottom: 0.25rem;
			@media ${device.mobile}{
				font-size:1.33rem;
			}
	  	}
		&:not(:last-child) {
			margin-bottom: 1.5rem;
		}
  	}

	#references {
		margin:0 0;
		line-height:0.875rem;
		letter-spacing: 0.01071em;
    }

	.narrow-content {
		width: 75%;
		margin: 0 auto;
		@media ${device.mobile}{
			width:unset;
		}
	}
	
	.definitions {
		@media ${device.mobile}{
			margin:0rem;
		  }
	}
	
	.definitions-section{
		@media ${device.mobile}{
			margin-top:3rem;
		}
	}

	.round-callout-box {
		@media ${device.mobile}{
			border-radius:5px;
		}
	}

	.slick-slide.slick-active {
		@media ${device.mobile}{
			width:325px !important
		}

		@media ${device.desktop}{
			width:974px !important;
		}

		&[data-index="1"]{
		
			@media ${device.mobile}{
				width:325px !important
			}

			@media ${device.desktop}{
				width:974px !important;
			}
		}
	}

	.slick-slider {
		@media ${device.mobile}{
		width:325px !important;
		}
		@media ${device.desktop}{
			width:974px !important;
	  	}
	}

	.slick-track {
		@media ${device.mobile} {
			display: flex;
			flex-direction: row;
			justify-content: space-evenly;
			align-items: stretch;
		}
	}

	.slick-track > .slick-slide,
	.slick-track > .slick-slide.slide-active {
		@media ${device.mobile}{
			width: 50% !important;
		}
	}

	.slick-dots {
		margin: 2.375rem 0 3.813rem 0;
		bottom:0;
		position:inherit;
		@media ${device.mobile}{
			margin-top:-2.25rem;
			margin-bottom:0;
		}
	}
  
	.slick-arrow {
		bottom:-.375rem !important;
  	}
  	
	.slick-next {
	
		@media ${device.mobile}{
			right:0.625rem;
	  	}
  	}

	.sliding-flex {
		display: flex;
		flex-direction: row;
		@media ${device.mobile}{
			flex-direction: column;
		}
	
		&> div {
			flex-basis: 50%;
			&:nth-child(1) {
				margin-right: 1rem;
			}
			&:nth-child(2) {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				border: 1px solid #440099;
				margin: 0 25px;
				padding: 50px;
				text-align:center;
				@media ${device.mobile}{
					margin-top:2.25rem;
					padding: 1.7rem 1.2rem;
				}
			}
		}
  	}

	.transcript {
		margin-top:3.563rem;
	}

	.why-treat-mac {
		margin: 0 auto;
		max-width: 100%;
		@media ${device.mobile}{
			.slick-slider {
				width: 100% !important;
				.slick-slide.slick-active {
					/* width: 313px !important; */
				}
			}
		}
		h1 {
			margin-bottom: 3rem;
		}
		h2 {
			margin-bottom: 1.0rem;
		}
	}
`;
