import React, { useState, useEffect, useRef } from "react";
import { graphql } from "gatsby";
import { isWindow } from "../components/Helpers";
import { WhyTreatMacStyles } from "../styles/PageWhyTreatMacStyles";
import { AudioTranscript } from "../components/AudioTranscript";
import {
	Container,
	Definitions,
	InterstitialInjector,
	References,
} from "../components";

import { SectionCarousel } from "../components/Carousels/SectionCarousel";

const WhyTreatMac = ({ data }) => {
	const slider = useRef();
	const [isHCP, setHCP] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const dataQuery = data.allMarkdownRemark.nodes[0],
		componentID = data.allMarkdownRemark.nodes[0].frontmatter.components_id,
		arrowText = data.allMarkdownRemark.nodes[0].frontmatter.arrowText,
		audioTracksWithProps =
			data.allMarkdownRemark.nodes[0].frontmatter.audioTracksWithProps[0],
		markdownID = `ed-why-treat-mac`;

	const [trackWProps, setTrackWProps] = useState("default");
	const [transcriptState, setTranscriptState] = useState(false);
	const interstitials = [
		{
			container: "important-safety-information-interstitial-container",
			trigger: "IMPORTANT SAFETY INFORMATION",
			disclaimer: false,
			setInner: false,
			externalURL: "https://www.arikaycehcp.com",
			injectorContainer: "interstitial-modal-wrap",
		},
	];
	useEffect(() => {
		if (isWindow) {
			let isHCP = sessionStorage.getItem("hcp");
			if (isHCP !== "true") {
				setHCP(false);
			} else {
				setHCP(true);
			}
		}
	}, [isHCP, setHCP]);
	const wtmSetTrackWProps = (next) => {
		let trackKey = ["default", "slide2"];
		setTrackWProps(trackKey[next]);
	};

	useEffect(() => {
		setLoaded(true);
	}, [setLoaded]);
	setTimeout(() => {
		if (isWindow) {
			if (window.screen.width < 768) {
				document.querySelector(
					"#insmed-logo-interstitial-modal"
				).style.display = "none";
				document.querySelector(
					"#insmed-logo-mobile-interstitial-modal-trigger > img"
				).src = "/images/insmed-logo-purple.svg";
				document.querySelector("#circle").style.transform =
					"translate(-0.6px, 0.6px)";
			}
		}
	}, 800);

	return (
		<>
			<Container
				componentID={componentID}
				markdownID={markdownID}
				noSubNav={true}
				query={dataQuery}
				trackWProps={audioTracksWithProps[trackWProps]}
				setTrackWProps={setTrackWProps}
				transcriptState={transcriptState}
				setTranscriptState={setTranscriptState}
			>
				<WhyTreatMacStyles id={`main`} className={`wrapper`}>
					<SectionCarousel
						arrowText={arrowText}
						markdownID={markdownID}
						slider={slider}
						slidesYouWantToMake={
							dataQuery.frontmatter.sectionSlides
						}
						setTrackWProps={wtmSetTrackWProps}
						className={`why-treat-mac show visible nomargin`}
					/>

					<Definitions
						markdownID={markdownID}
						definitions={dataQuery.frontmatter.definitions}
					/>
					<References
						markdownID={markdownID}
						references={dataQuery.frontmatter.references}
					/>
					<AudioTranscript
						id={`audio-transcript-main`}
						markdownID={markdownID}
						transcriptState={transcriptState}
						setTranscriptState={setTranscriptState}
						transcriptCopy={
							audioTracksWithProps[trackWProps].transcript
						}
					/>
					{loaded ? (
						<InterstitialInjector query={interstitials} />
					) : null}
				</WhyTreatMacStyles>
			</Container>
		</>
	);
};

export const query = graphql`
	{
		allMarkdownRemark(
			filter: {
				frontmatter: { markdown_id: { eq: "ed-why-treat-mac-content" } }
			}
		) {
			nodes {
				frontmatter {
					slug
					description
					keywords
					markdown_id
					title
					definitions
					references
					components_id
					audioTracksWithProps {
						default {
							trackID
							isiContent
							src
							title
							transcript
						}
						slide2 {
							trackID
							isiContent
							src
							title
							transcript
						}
					}
					sectionSlides
					arrowText
				}
				id
				html
			}
		}
	}
`;

export default WhyTreatMac;
